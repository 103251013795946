import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, delay, filter, of, switchMap, take, tap } from 'rxjs';
import { PowerBiApi } from '../models/power-bi-models';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { PowerBiService } from './power-bi.service';
import { ActivatedRoute } from '@angular/router';
import {
  AccountInfo,
  EventMessage,
  EventType,
  InteractionStatus,
} from '@azure/msal-browser';
import { BlueOcean } from '../models/bluelake-models';
import { products } from '../data/product-data';

@Injectable({
  providedIn: 'root',
})
export class ApplicationStateService {
  // hard coded products
  products = products;

  // State variables
  private _isAuthenticated = new BehaviorSubject<boolean>(false);
  isAuthenticated$ = this._isAuthenticated.asObservable();

  private _loginDisplay = new BehaviorSubject<boolean>(false);
  loginDisplay$ = this._loginDisplay.asObservable();

  private _loadingWorkspaces = new BehaviorSubject<boolean>(false);
  loadingWorkspaces$ = this._loadingWorkspaces.asObservable();

  private _loadingReports = new BehaviorSubject<boolean>(true);
  loadingReports$ = this._loadingReports.asObservable();

  private _error = new BehaviorSubject<string | undefined>(undefined);
  error$ = this._error.asObservable();

  private _accounts = new BehaviorSubject<AccountInfo[]>([]);
  accounts$ = this._accounts.asObservable();

  private _activeAccount = new BehaviorSubject<AccountInfo | null | undefined>(
    undefined,
  );
  activeAccount$ = this._activeAccount.asObservable();

  private _availableWorkspaces = new BehaviorSubject<PowerBiApi.Workspace[]>(
    [],
  );
  availableWorkspaces$ = this._availableWorkspaces.asObservable();

  private _activeWorkspace = new BehaviorSubject<
    PowerBiApi.Workspace | undefined
  >(undefined);
  activeWorkspace$ = this._activeWorkspace.asObservable();

  private _availableReports = new BehaviorSubject<PowerBiApi.Report[]>([]);
  availableReports$ = this._availableReports.asObservable();

  private _alsoAvailableNotBoughtProducts = new BehaviorSubject<
    BlueOcean.Product[]
  >([]);
  alsoAvailableNotBoughtProducts$ =
    this._alsoAvailableNotBoughtProducts.asObservable();

  private _activeReport = new BehaviorSubject<PowerBiApi.Report | undefined>(
    undefined,
  );
  activeReport$ = this._activeReport.asObservable();

  // Services
  private authService = inject(MsalService);
  private broadcastService = inject(MsalBroadcastService);
  private powerBiService = inject(PowerBiService);
  private activatedRoute = inject(ActivatedRoute);

  constructor() {
    console.log('ApplicationStateService initialized');
    this.activatedRoute.queryParams.subscribe();
    this.initialize();
  }

  initialize() {
    // when interaction status changes to none, check for active account because it may have changed
    this.broadcastService.inProgress$
      .pipe(
        // delay(500),
        tap(() => {
          this._error.next(undefined);
          this._loadingWorkspaces.next(true);
        }),
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None,
        ),
        switchMap((msg) => {
          console.log('account switch detected...');
          this._error.next(undefined);
          const account = this.checkAndSetActiveAccount();
          if (account) {
            console.log(
              `(1) User authenticated: ${account.username} (${account.tenantId})`,
            );
            console.log(`roles: ${account.idTokenClaims?.roles}`);
            console.log(`(2) Fetching workspaces for ... ${account.username}`);
            // JWT token is automatically attached to request by MSAL interceptor
            return this.powerBiService.getOwnWorkspaces();
          } else {
            console.log('(1) No user authenticated');
            this._isAuthenticated.next(false);
            return of([]);
          }
        }),
      )
      .subscribe({
        next: (workspaces: PowerBiApi.Workspace[]) => {
          if (this._isAuthenticated.value) {
            this._availableWorkspaces.next(workspaces);
            console.log(`(3) Workspaces fetched: ${workspaces.length}`);
            this.checkAndSetActiveWorkspace();
            this._loadingWorkspaces.next(false);
          }
        },
        error: (error) => {
          console.error('(3) Error fetching workspaces', error);
          this._loadingWorkspaces.next(false);
          this._error.next(error);
        },
      });
  }

  loadUserData() {
    // Reset error and start loading workspaces
    this._error.next(undefined);
    this._loadingWorkspaces.next(true);

    // Check and set the active account
    const account = this.checkAndSetActiveAccount();
    if (account) {
      console.log(
        `(1) User authenticated: ${account.username} (${account.tenantId})`,
      );
      console.log(`(2) Fetching workspaces for ... ${account.username}`);
      // Fetch workspaces for the authenticated user
      this.powerBiService.getOwnWorkspaces().subscribe({
        next: (workspaces: PowerBiApi.Workspace[]) => {
          if (this._isAuthenticated.value) {
            this._availableWorkspaces.next(workspaces);
            console.log(`(3) Workspaces fetched: ${workspaces.length}`);
            this.checkAndSetActiveWorkspace();
            this._loadingWorkspaces.next(false);
          }
        },
        error: (error) => {
          console.error('(3) Error fetching workspaces', error);
          this._loadingWorkspaces.next(false);
          this._error.next(error);
        },
      });
    } else {
      console.log('(1) No user authenticated');
      this._isAuthenticated.next(false);
      this._loadingWorkspaces.next(false);
    }
  }

  checkAndSetActiveAccount(): AccountInfo | null {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     */
    console.log('checking for accounts...');
    let activeAccount = this.authService.instance.getActiveAccount();
    this._activeAccount.next(activeAccount);
    this._accounts.next(this.authService.instance.getAllAccounts());
    this._isAuthenticated.next(
      this._accounts.value.length > 0 && !!activeAccount,
    );

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }

    return activeAccount;
  }

  checkAndSetActiveWorkspace() {
    // check if active workspace is set in local storage
    let activeWorkspaceId = localStorage.getItem('activeWorkspaceId');
    if (activeWorkspaceId) {
      this.setActiveWorkspace(activeWorkspaceId);
    } else {
      // set first workspace as active workspace
      let firstWorkspace = this._availableWorkspaces.value[0];
      if (firstWorkspace) {
        this.setActiveWorkspace(firstWorkspace.id);
      } else {
        console.log('No workspaces available');
      }
    }
  }

  setActiveWorkspace(workspaceId: string) {
    console.log(`Setting active workspace..`);
    let workspace = this._availableWorkspaces.value.find(
      (w) => w.id === workspaceId,
    );
    this._activeWorkspace.next(workspace);
    if (workspace)
      console.log(`Active workspace set: ${workspace.displayName}`);
    // set id of active workspace in local storage
    localStorage.setItem('activeWorkspaceId', workspaceId);
    // fetch reports for workspace
    this._loadingReports.next(true);
    console.log(`Fetching reports for workspace...`);
    this.powerBiService.getReportsFromWorkspace(workspaceId).subscribe({
      next: (reports: PowerBiApi.Report[]) => {
        const availableProductNames = this.products.map(
          (product) => product.name,
        );
        this._availableReports.next(
          reports
            .filter((r) => availableProductNames.includes(r.name))
            .sort((a, b) => {
              if (a.name === 'Governance') {
                return 1; // 'Governance' comes last
              } else if (b.name === 'Governance') {
                return -1; // 'Governance' comes first
              } else {
                return a.name.localeCompare(b.name); // sort alphabetically
              }
            }),
        );
        console.log(`Reports fetched: ${reports.length}`);
        this.setNotBoughtProducts(reports);
        // set active report if available
        let activeReportId = localStorage.getItem('activeReportId');
        if (activeReportId) {
          this.setActiveReport(activeReportId);
        }
        this._loadingReports.next(false);
      },
      error: (error) => {
        console.error('Error fetching reports', error);
        this._error.next(error);
        this._loadingReports.next(false);
      },
    });
  }

  setActiveReport(reportId: string) {
    console.log('Setting active report...');
    let report = this._availableReports.value.find((r) => r.id === reportId);
    if (report) console.log(`Active report set: ${report.name}`);
    // set id of active report in local storage
    localStorage.setItem('activeReportId', reportId);
    this._activeReport.next(report);
  }

  setNotBoughtProducts(boughtReports: PowerBiApi.Report[]) {
    let boughtProductNames = boughtReports.map((report) => report.name);
    let notBoughtProducts = this.products
      .filter((product) => !boughtProductNames.includes(product.name))
      .sort((a, b) => a.name.localeCompare(b.name));
    this._alsoAvailableNotBoughtProducts.next(notBoughtProducts);
    console.log(
      'Not bought products:',
      notBoughtProducts.map((p) => p.name),
    );
  }
}
